<template>
  <v-dialog persistent v-model="mostrar" max-width="350">
    <v-card class="white">
      <v-card-title class="headline primary--text">{{ titulo }}</v-card-title>

      <v-card-text class="gris3--text" v-html="mensaje"> </v-card-text>
      <v-textarea
        outlined
        maxlength="500"
        counter="500"
        v-if="textoCampo && !esPw"
        v-model="texto"
        rows="2"
        :label="textoCampo"
        class="pa-3"
      >
      </v-textarea>
      <v-text-field
        type="password"
        v-if="textoCampo && esPw"
        :label="textoCampo"
        v-model="texto"
        class="pa-3"
        outlined
      >
      </v-text-field>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text class="font-weight-black" @click="aceptar">
          Aceptar
        </v-btn>
        <v-btn color="primary" text @click="cancelar">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  name: "confirmar",
  data: function() {
    return {
      titulo: "",
      mensaje: "",
      mostrar: false,
      resolve: null,
      reject: null,
      textoCampo: "",
      texto: ""
    };
  },
  beforeCreate: function() {
    var self = this;
    this.$root.$on("mostrarConfirmar", function(datos) {
      self.titulo = datos.titulo;
      self.mensaje = datos.mensaje;
      self.resolve = datos.resolve;
      self.reject = datos.reject;
      self.mostrar = true;
      self.textoCampo = datos.textoCampo;
      self.texto = "";
      self.esPw = datos.esPw;
    });
  },
  methods: {
    aceptar: function() {
      this.resolve(this.texto);
      this.mostrar = false;
    },
    cancelar: function() {
      if (this.reject) {
        this.reject();
      }
      this.mostrar = false;
    }
  }
};
</script>
