<template>
  <v-app>
    <router-view></router-view>
    <v-dialog v-model="$root.isLoading" persistent width="300">
      <v-card color="primary" dark>
        <br />
        <v-card-text>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      class="ma-2"
      multi-line
      color="error"
      :timeout="5000"
      right
      :top="true"
      v-if="$root.mensajeError"
      v-model="$root.mensajeError"
    >
      <v-icon color="white" large>error_outline</v-icon>
      {{ $root.mensajeError }}
      <v-btn color="white" text v-on:click.native="$root.mensajeError = null"
        >Cerrar</v-btn
      >
    </v-snackbar>
    <v-snackbar
      class="ma-2"
      multi-line
      color="success"
      :timeout="5000"
      right
      :top="true"
      v-if="$root.mensajeExito"
      v-model="$root.mensajeExito"
    >
      <v-icon color="white" large>info</v-icon>
      {{ $root.mensajeExito }}
      <v-btn color="white" text v-on:click.native="$root.mensajeExito = null"
        >Cerrar</v-btn
      >
    </v-snackbar>
    <confirmar></confirmar>
  </v-app>
</template>

<script>
import router from './router';
import '@/styles/overrides.sass';
import confirmar from './components/Confirmar';
export default {
  name: 'App',
  router,
  components: {confirmar},

  data: () => ({}),
};
</script>
