import Vue from 'vue';
import config from '../config';

var mixin = {
  methods: {
    apiPost: function(metodo, p) {
      var fullPath;
      fullPath = apiPath + metodo;

      return _axios.post(fullPath, '=' + JSON.stringify(p), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
    },
    apiGet: function(metodo, p) {
      var fullPath;
      fullPath = apiPath + metodo;

      return _axios.get(fullPath, '=' + JSON.stringify(p), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    fechaSQL(fecha) {
      return this.formatDate(fecha.split('T')[0]);
    },
    horaSQL(fecha) {
      return fecha.split('T')[1].substring(0, 5);
    },
    validarEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    verImagen(imagen) {
      window.open(this.config.RutaUploadImagenes + imagen, '_BLANK');
    },
    fechaPicker: function(date) {
      var mm = date.getMonth() + 1;
      var dd = date.getDate();

      return [
        date.getFullYear(),
        mm < 10 ? '0' + mm : mm,
        dd < 10 ? '0' + dd : dd,
      ].join('-');
    },
    confirmar(titulo, mensaje, textoCampo, esPw) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$root.$emit('mostrarConfirmar', {
          titulo: titulo,
          mensaje: mensaje,
          resolve: resolve,
          reject: reject,
          textoCampo: textoCampo,
          esPw: !!esPw,
        });
      });
    },
    horaFecha(fecha) {
      const hh = fecha.getHours();
      const mm = fecha.getMinutes();

      return [hh < 10 ? '0' + hh : hh, mm < 10 ? '0' + mm : mm].join(':');
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
  },
  data: function() {
    return {
      mensajeExito: null,
      isLoading: false,
      mensajeError: null,
      hoy: new Date(),
      config,
    };
  },
};

Vue.mixin(mixin);
