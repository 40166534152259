export default {
  LoginRedireccionar: true,
  //Produccion
  RutaApi: 'https://magallanesfdvapi.gcolumbia.com',
  //Desarrollo
  //RutaApi: 'https://localhost:7137',
  PBI_API_Application: '95c5889b-29a6-49c0-aa58-fd8a6c450614'
  //RutaApi: 'https://magallanesfdvapi.gcolumbia.com/'
  //Web: 'https://magallanesfdv.gcolumbia.com

};
