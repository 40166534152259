import Vue from "vue";
import VueRouter from "vue-router";
import Modulos from "../services/modulos";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/analiticos",
    name: "Analíticos",
    component: () => import("../views/Analiticos.vue"),
    children: [
      {
        path: 'pbi/:reporteId',
        name: 'Reporte PowerBI',
        component: () => import("../views/pbi/ReportePBI.vue"),
        props: true, // Pasar reporteId como prop
      },
      {
        path: 'reporte/:reporteId',
        name: 'ReportePBI2',
        component: () => import("../views/pbi/ReportePBI2.vue"),
        props: true, // Pasar reporteId como prop
      },
      {
        path: 'configuracion/areatrabajo/',
        name: 'Áreas de Trabajo',
        component: () => import("../views/configuracion/AreasTrabajo.vue"),
        props: true, // Pasar reporteId como prop
      },
      {
        path: 'configuracion/reportes/',
        name: 'Reportes',
        component: () => import("../views/configuracion/Reportes.vue"),
        props: true, // Pasar reporteId como prop
      },
      {
        path: 'configuracion/perfiles/',
        name: 'Perfiles',
        component: () => import("../views/configuracion/Perfiles.vue"),
        props: true, // Pasar reporteId como prop
      },
      {
        path: 'configuracion/grupos/',
        name: 'Grupos',
        component: () => import("../views/configuracion/Grupos.vue"),
        props: true, // Pasar reporteId como prop
      },

    ],

  },
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  routes,
});


router.beforeEach(async (to, _from, next) => {
  if (to.name !== "Login" && !localStorage.getItem("accessToken")) {
    next("/");
  }
  /*
  else if (to.name !== "Login") {
    const module = Modulos.find(
      (m) => to.fullPath.toLowerCase().indexOf(m.Router.toLowerCase()) > -1
    );
    if (!module) {
      next();
    } else {
      const permisos = JSON.parse(localStorage.getItem("modulos"));
      if (permisos.some((p) => p === module.ModuloID)) {
        next();
      } else {
        next("/");
      }
    }
  } 
    */
  else {

    next();
  }
});


export default router;
