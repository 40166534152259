import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#002855',
                secondary: '#c3c3c3',
                gris: '#EEEEEE',
                gris2: '#f3f3f3',
                gris3: '#666',
            },
        },
    },
});
