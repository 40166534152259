import Vue from 'vue'
import './plugins/mixins'
import App from './App.vue'
import router from './router';
import vuetify from './plugins/vuetify'
import { PublicClientApplication } from "@azure/msal-browser";


Vue.config.productionTip = false;

// Configuración de MSAL
const msalConfig = {
  auth: {
    clientId: "95c5889b-29a6-49c0-aa58-fd8a6c450614", // Application ID de Azure
    authority: "https://login.microsoftonline.com/2ddd7e3e-28b0-4bc3-aaab-c0e6b984883e", // Tenant ID de Azure
    redirectUri: window.location.origin, // URL de redirección después de iniciar sesión
  },
  cache: {
    cacheLocation: "localStorage", // Puedes cambiar a "sessionStorage" según tus necesidades
    storeAuthStateInCookie: true, // Necesario para navegadores más antiguos
  },
};

// Creación de la instancia de MSAL
const msalInstance = new PublicClientApplication(msalConfig);

// Asegúrate de que MSAL esté inicializado
await msalInstance.initialize();

// Hacemos que msalInstance esté disponible globalmente en Vue
Vue.prototype.$msalInstance = msalInstance;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
